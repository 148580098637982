.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin: .5rem 0;
  }
  .loader {
    align-self: center;
  }
}
