$primary: #1565c0;
$primary-light: #5e92f3;
$primary-dark: #003c8f;
$secondary: #fdd835;
$secondary-light: #ffff6b;
$secondary-dark: #c6a700;
$body: #fafafa;
$black: #000;
$gray: #6d6d6d;
$gray-input: #555;
$gray-light: #ddd;
$danger: #d0021b;
$success: #43a047;
$shadow: rgb(0 0 0 / 30%);
$shadow-light: rgb(0 0 0 / 18%);
$divider: rgba(0, 0, 0, 0.1);
$disabled: #c7c7c7;

$smartphone: "only screen and (min-width: 320px) and (max-width: 812px)";
$tablet: "only screen and (min-width: 813px) and (max-width: 1024)";
$tablet-max: "only screen and (max-width: 1024px)";
$desktop-min: "only screen and (min-width: 1025px)";
