@import "../../../../theme/variables";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: .5rem 0;
    display: flex;
    align-items: baseline;
    justify-content: space-around;

    .activeEntries {
      background-color: $success;
      border-radius: 50%;
      padding: .2rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      font-weight: 700;
      color: $body;
    }
  }

  .tableContainer {
    flex-grow: 1;
    overflow-y: auto;
  }

  .newEntryContainer {
    padding: .5rem;
  }
}

@media #{$desktop-min} {
  .container {
    .header {
      justify-content: flex-end;

      > * {
        margin: 0 1rem;
      }
    }

    .tableContainer {
      order: 2;
    }

    .newEntryContainer {
      align-self: flex-end;
    }
  }
}
