@import "./theme/variables";

.appWrapper {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:root, [data-amplify-theme] {
  --amplify-colors-border-error: hsl(353, 98%, 41%) !important;
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
  --amplify-colors-background-success: var(--amplify-colors-green-20);
  --amplify-colors-background-error: hsl(353, 88%, 90%) !important;
  --amplify-colors-background-warning: var(--amplify-colors-orange-20);
  --amplify-colors-background-info: #5e92f390 !important;
  --amplify-colors-background-tertiary: var(--amplify-colors-neutral-20);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-primary: var(--amplify-colors-white);
  --amplify-colors-font-success: #43a047 !important;
  --amplify-colors-font-error: hsl(353, 98%, 41%) !important;
  --amplify-colors-font-warning: var(--amplify-colors-orange-90);
  --amplify-colors-font-info: #c6a700 !important;
  --amplify-colors-font-inverse: var(--amplify-colors-white);
  --amplify-colors-font-tertiary: var(--amplify-colors-neutral-80);
  --amplify-colors-font-secondary: #5e92f3 !important;
  --amplify-colors-font-primary: #003c8f !important;
  --amplify-colors-brand-secondary-100: #fdd835 !important;
  --amplify-colors-brand-secondary-90: #fdd83590 !important;
  --amplify-colors-brand-secondary-80: #fdd83580 !important;
  --amplify-colors-brand-secondary-60: #fdd83560 !important;
  --amplify-colors-brand-secondary-40: #fdd83540 !important;
  --amplify-colors-brand-secondary-20: #fdd83520 !important;
  --amplify-colors-brand-secondary-10: #fdd83510 !important;
  --amplify-colors-brand-primary-100: hsl(212, 80%, 42%) !important;
  --amplify-colors-brand-primary-90: hsl(212, 80%, 47%) !important;
  --amplify-colors-brand-primary-80: hsl(212, 80%, 52%) !important;
  --amplify-colors-brand-primary-60: hsl(212, 80%, 57%) !important;
  --amplify-colors-brand-primary-40: hsl(212, 80%, 62%) !important;
  --amplify-colors-brand-primary-20: hsl(212, 80%, 67%) !important;
  --amplify-colors-brand-primary-10: hsl(212, 80%, 72%) !important;
}
