@import "../../../../theme/variables";

.table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-wrap: wrap;

    &.even {
      background-color: $secondary-light;
    }

    > * {
      margin: 0 .5rem;
    }

    .status {
      display: flex;
      align-items: center;
    }
  }
}

@media #{$desktop-min} {
  .table {
    .row {
      justify-content: space-between;
    }
  }
}
