@import "../../../../theme/variables";

.light {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin: 0 .5rem;
  animation: light 1.5s steps(10000) infinite;
  display: block;

  &.valid {
    background-color: $success;
  }

  &.unhandled {
    background-color: orange;
  }

  &.invalid {
    background-color: $danger;
  }
}

@keyframes light {
  0% {
    opacity: 100;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
