@import "./theme/variables";

@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: "Lato", "Open Sans", "Helvetica Neue", sans-serif;
  background-color: $body;
}

