@import "../../../../theme/variables";

.wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);

  .content {
    height: max-content;
    width: max-content;
    max-height: 90%;
    max-width: 90%;
    overflow-y: auto;
    background-color: $body;
    border-radius: 5px;
    padding: .5rem;
    box-shadow: 0 2px 3px 2px $shadow;
  }
}
